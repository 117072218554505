import { graphql, PageProps } from "gatsby"
import React, { FunctionComponent } from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import ArticleTemplate from "../components/blog/Article"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SocialShare, { SocialShareType } from "../components/blog/SocialShare"
import SimilarArticle from "../components/blog/SimilarArticle"
import Container from "../components/Container"
import YouTube from '../components/mdx/YouTube'
import BlogCta from '../components/mdx/BlogCta'
import useSiteMetadata from "../hooks/useSiteMetadata"
import {
  Article as ArticleQueryResult, ArticleAuthor,
  ArticleSummary as ArticleSummaryQueryResult
} from "../types/QueryResults"

// -----------------------------------------------------------------------------
// MDX shortcodes
// -----------------------------------------------------------------------------
const shortcodes = { YouTube, BlogCta }

export const query = graphql`
  query($slug: String!, $related: [String!], $author: String) {
    author: authorYaml(id: {eq: $author}) {
      id
      bio
      photo {
        childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
        }
      }
    }
    articleHeroImage: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    articleOGImage: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        ogImage: heroImage {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
    articleSummary: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        author
        date(formatString: "YYYY-MM-DD")
        slug
        seo {
          description
          title
        }
        title
      }
      body
      tableOfContents(
        maxDepth: 2
      )
      timeToRead
    }
    relatedArticles: allMdx(filter: { frontmatter: { slug: { in: $related } } }) {
      edges {
        node {
          frontmatter {
            author
            date(formatString: "YYYY-MM-DD")
            heroImage {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 450) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            slug
            summary
            title
          }
        }
      }
    }
  }
`

interface QueryResult {
  author: ArticleAuthor
  articleHeroImage: ArticleQueryResult
  articleOGImage: ArticleQueryResult
  articleSummary: ArticleQueryResult
  relatedArticles: { edges: { node: ArticleSummaryQueryResult }[] }
}

type ArticleProps = PageProps<QueryResult>

/**
 * Generate the related article components
 *
 * @param articles
 */
function makeRelatedArticles(articles: { node: ArticleSummaryQueryResult }[]) {
  return articles.map(({ node }) => {
    const frontmatter = node.frontmatter

    return (
      <SimilarArticle
        key={node.id}
        articleUrl={`/blog/${frontmatter.slug}`}
        authorName={frontmatter.author}
        heroImage={frontmatter.heroImage.childImageSharp}
        publicationDate={new Date(frontmatter.date)}
        summary={frontmatter.summary}
        title={frontmatter.title}
      />
    )
  })
}

/**
 * Make the social share link components
 *
 * @param articleUrl
 * @param shareText
 */
function makeSocialShareLinks(articleUrl: string, shareText: string) {
  return [SocialShareType.LINKED_IN, SocialShareType.TWITTER].map(type => (
    <SocialShare
      key={type}
      articleUrl={articleUrl}
      shareText={shareText}
      type={type}
    />
  ))
}

/**
 * Make the article component
 */
const Article: FunctionComponent<ArticleProps> = ({ data, location }) => {
  const { siteUrl } = useSiteMetadata()
  const { author } = data
  const articlePath = location.pathname

  const articleSummary = data.articleSummary.frontmatter
  const articleBody =  data.articleSummary.body
  const articleTableOfContents =  data.articleSummary.tableOfContents
  const articleTimeToRead =  data.articleSummary.timeToRead
  const articleOGImage = data.articleOGImage.frontmatter.ogImage.childImageSharp.fixed.src
  const articleHeroImage = data.articleHeroImage.frontmatter.heroImage.childImageSharp

  const shareLinks = makeSocialShareLinks(
    `${siteUrl}${articlePath}`,
    articleSummary.seo.description
  )
  const relatedArticles = makeRelatedArticles(data.relatedArticles.edges)

  return (
    <Layout disableIntercom>
      <Seo
        description={articleSummary.seo.description}
        pathname={articlePath}
        title={articleSummary.seo.title}
        image={articleOGImage}
      />

      <ArticleTemplate
        authorName={articleSummary.author}
        authorData={author}
        heroImage={articleHeroImage}
        publicationDate={new Date(articleSummary.date)}
        readingTime={articleTimeToRead}
        relatedArticles={relatedArticles}
        socialShareLinks={shareLinks}
        tableOfContents={articleTableOfContents}
        title={articleSummary.title}
      >
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{articleBody}</MDXRenderer>
        </MDXProvider>
      </ArticleTemplate>

      <section className="bg-blue-500 rounded-lg text-white" id="footerCta">
        <Container>
          <div className="py-8">
            <div className="flex flex-row flex-wrap items-center justify-center text-center">
              <div className="p-4" style={{ minWidth: "25ch" }}>
                Speak to us to level up your partner discovery.
              </div>

              <div className="p-4">
                <a
                  className="
                    bg-white border border-white cursor-pointer duration-500 font-bold block px-8 py-3 rounded-full shadow-md text-blue
                    hover:bg-blue-100 hover:shadow-lg hover:text-blue-800"
                  href="/signup"
                >
                  Book a demo
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default Article
