import React, {FunctionComponent, ReactNode, useEffect, useState} from "react"
import { HideOn } from 'react-hide-on-scroll'
import styles from "./BlogCta.module.css"

const instanceCounter = (() => {
    let count = 0;
    return {
        increment: () => ++count,
        decrement: () => --count,
        getCount: () => count,
    };
})();


interface Props {
    heading?: string
    text?: string
    label?: string
    url?: string
}

const BlogCta: FunctionComponent<Props> = ({
                                                heading = "Speak to us to level up your partner discovery.",
                                                text,
                                                label= "Book a demo",
                                                url= "/signup",
                                            }) => {
    const [open, setOpen] = useState(true)
    const [isDuplicate, setIsDuplicate] = useState(false);

    useEffect(() => {
        const currentCount = instanceCounter.increment();
        setIsDuplicate(currentCount > 1);

        return () => {
            instanceCounter.decrement();
        };
    }, []);

    const Popup = ({children}) => (
        <div className={`${styles.popup} w-full bg-white max-w-md rounded-lg py-0 pb-6 px-6 fixed right-0 bottom-0 mb-3 mr-3 z-40 shadow-xl hidden  xl:block border border-blue`}>
            <div onClick={() => setOpen(!open)} className="absolute cursor-pointer inline-block" style={{top: 10, right: 10, width: 15, height: 15}}>
                <div className="Header-module--menuBtn--1OoBh block w-5 absolute transform -translate-x-1/2 -translate-y-1/2">
                    <span aria-hidden="true"
                      className="rotate-45 Header-module--menuLine--2nlao block absolute w-5 bg-black transform transition duration-300 ease-in-out" />
                    <span
                        aria-hidden="true"
                        className="-rotate-45 w-5 Header-module--menuLine--2nlao block absolute right-0 w-3 bg-black transform  transition duration-300 ease-in-out" />
                </div>
            </div>
            {children}
        </div>
    )

    const internals =
        <>
            <div className="p-4">
                <h3>{heading}</h3>
                {!!text && <p>{text}</p>}
            </div>
            <a
                className={`${styles.button} text-center text-white no-underline w-full max-w-xs mx-auto
                      border border-white cursor-pointer duration-500 font-bold block px-8 py-3 rounded-full shadow-md
                          hover:shadow-lg`}
                href={url}
            >
                {label}
            </a>
        </>

    return (
        <>
            {!isDuplicate && <HideOn height={1500} inverse>
                {Boolean(open) &&  <Popup>{internals}</Popup>}
            </HideOn>}
            <div className="border border-blue rounded-lg pt-6 pb-10 text-center my-12 mx-0 md:mx-12">
                <div className="max-w-2xl mx-auto">
                    {internals}
                </div>
            </div>
        </>
    )
}

export default BlogCta