import React, { FunctionComponent } from "react"
import LinkedIn from "../icons/LinkedIn"
import Twitter from "../icons/Twitter"

enum SocialShareType {
  LINKED_IN = "linkedin",
  TWITTER = "twitter",
}

interface Props {
  articleUrl: string
  shareText: string
  type: SocialShareType
}

function getIcon(type: string, title: string) {
  const className = "h-6 w-6"

  switch (type) {
    case SocialShareType.LINKED_IN:
      return <LinkedIn className={className} title={title} />
    case SocialShareType.TWITTER:
      return <Twitter className={className} title={title} />
    default:
      return null
  }
}

function getTitle(type: string) {
  switch (type) {
    case SocialShareType.LINKED_IN:
      return "Share this article on LinkedIn"
    case SocialShareType.TWITTER:
      return "Share this article on Twitter"
    default:
      return "Share this article"
  }
}

function getLinkedInShareLink(url: string, text: string) {
  return `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${text}`
}

function getTwitterShareLink(url: string, text: string) {
  return `https://twitter.com/intent/tweet?url=${url}&text=${text}`
}

function getShareLink(type: string, articleUrl: string, shareText: string) {
  const url = encodeURIComponent(articleUrl)
  const text = encodeURIComponent(shareText)

  switch (type) {
    case SocialShareType.LINKED_IN:
      return getLinkedInShareLink(url, text)

    case SocialShareType.TWITTER:
      return getTwitterShareLink(url, text)
  }
}

const SocialShare: FunctionComponent<Props> = ({
  articleUrl,
  shareText,
  type,
}) => {
  const title = getTitle(type)
  const icon = getIcon(type, title)
  const shareLink = getShareLink(type, articleUrl, shareText)

  return (
    <a
      className="bg-black duration-500 h-10 inline-flex items-center justify-center rounded-full text-white transition-colors w-10 hover:bg-blue-200 hover:text-black"
      href={shareLink}
      target="_blank"
      title={title}
    >
      {icon}
    </a>
  )
}

export { SocialShareType }
export default SocialShare
