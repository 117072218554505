import React, { FunctionComponent } from "react"
import { Link } from 'gatsby'
import styles from "./ArticleContents.module.css"

interface Props {
  items: {
    url: string
    title: string
  }[]
}

const ArticleContents: FunctionComponent<Props> = ({ items }) => (
  <div className={styles.root}>
    <h2 className={styles.title}>Table of contents</h2>
    <nav className={styles.nav}>
      {items.length && (
        <ul>
          {items.map((item) => (
            <li key={item.url}>
              <Link to={item.url}>{item.title}</Link>
            </li>
          ))}
        </ul>
      )}
    </nav>
  </div>
)

export default ArticleContents
