import React, { FunctionComponent } from "react"
import Img, { GatsbyImageProps } from "gatsby-image"
import ArticleContents from "./ArticleContents"
import ArticleMetadata from "./ArticleMetadata"
import { Link } from "gatsby"
import Pill from "../Pill"
import Container from "../Container"
import AuthorBlock from "./AuthorBlock"

interface ArticleAuthor {
  id: string
  bio: string
  linkedin?: string
  photo?: string
}

interface Props {
  authorName: string
  authorData: ArticleAuthor
  heroImage: GatsbyImageProps
  publicationDate: Date
  readingTime: number
  relatedArticles: JSX.Element[]
  socialShareLinks: JSX.Element[]
  tableOfContents: {
    items: {
      url: string
      title: string
    }[]
  }
  title: string
}

const Article: FunctionComponent<Props> = ({
  authorName,
  authorData,
  children,
  heroImage,
  publicationDate,
  readingTime,
  relatedArticles,
  socialShareLinks,
  tableOfContents,
  title,
}) => (
  <article>
    <header>
      <Pill bgClass="bg-green-100" variant="bottom-right">
        <Container>
          <div className="br-prose py-12 xxl:py-24" id="articleContent">
            <div className="br-col-gap md:grid md:grid-cols-12">
              <div className="md:col-span-10 md:col-start-2 xxl:col-span-8 xxl:col-start-3">
                <h1 className="mb-8 text-center">{title}</h1>

                <div className="text-center">
                  <ArticleMetadata
                    authorName={authorName}
                    publicationDate={publicationDate}
                    readingTime={readingTime}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Pill>
    </header>

    {heroImage && (
      <div className="-mt-6 relative z-20">
        <Container>
          <div className="br-col-gap md:grid md:grid-cols-12">
            <div className="md:col-span-10 md:col-start-2 xxl:col-span-8 xxl:col-start-3">
              <Img fluid={heroImage.fluid} />
            </div>
          </div>
        </Container>
      </div>
    )}

    <section className="py-12">
      <Container>
        <div className="br-col-gap md:grid md:grid-cols-12">
          <div className="md:col-span-10 md:col-start-2 xxl:col-span-8 xxl:col-start-3">
            <div className="xxxl:float-right xxxl:ml-24">
              <ArticleContents items={tableOfContents.items} />
            </div>

            <section className="br-prose" id="articleContent">{children}</section>

            {!!authorData && <AuthorBlock id={authorData.id} bio={authorData.bio} linkedin={authorData.linkedin} photo={authorData.photo} /> }

          </div>

        </div>
      </Container>
    </section>



    {socialShareLinks && (
      <footer>
        <Container>
          <div className="br-col-gap md:grid md:grid-cols-12">
            <div className="border-black border-t pt-4 md:col-span-10 md:col-start-2 xxl:col-span-8 xxl:col-start-3">
              <ul className="flex items-center justify-start">
                {socialShareLinks.map(link => (
                  <li key={link.key} className="mr-4">
                    {link}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Container>
      </footer>
    )}


    {relatedArticles && (
      <aside className="mt-24">
        <Pill bgClass="bg-green-100" variant="top-left">
          <Container>
            <div className="br-col-gap py-12 md:grid md:grid-cols-12 md:py-16">
              <div className="md:col-span-10 md:col-start-2 xxxl:col-span-8 xxxl:col-start-3">
                <h2 className="font-bold font-serif mb-10 text-4 text-center w-full md:text-6">
                  Similar articles
                </h2>

                <div className="br-col-gap grid grid-cols-4 items-start row-gap-8">
                  {relatedArticles.map((article, index) => (
                    <div key={index} className="col-span-2 xxl:col-span-1">
                      {article}
                    </div>
                  ))}
                </div>

                <div className="br-prose mt-16 text-1 text-center xxl:text-2">
                  <Link to="/blog">See all articles</Link>
                </div>
              </div>
            </div>
          </Container>
        </Pill>
      </aside>
    )}
  </article>
)

export default Article
