import React, { FunctionComponent } from "react"
import kebabCase from "lodash/kebabCase"
import { Link, } from "gatsby"
import Img from 'gatsby-image'
import Container from "../Container"


interface Props {
  id: string
  bio: string
  photo?: { childImageSharp: { fluid: { src: string } } }
}

const AuthorBlock: FunctionComponent<Props> = ({
                                                     id,
                                                     bio,
                                                    photo

                                                   }) => {
  const authorLink = `/blog/authors/${kebabCase(id)}`
  const photoAuthor = photo?.childImageSharp.fluid

  return (
    <div className="rounded-xl my-16 pb-4 mx-6  shadow-xl bg-white border-blue" style={{border: '3px #0163FA solid'}}>
      <div className="br-prose py-6 xxl:py-14">
        <div className="br-col-gap md:grid md:grid-cols-12">
          <div className="md:col-span-2 md:col-start-2 flex justify-center mb-8 md:justify-start">
            {!!photoAuthor &&
            <Img fluid={photoAuthor} className="rounded-full w-full" style={{ alignSelf: "center" }} alt="avatar"/>
            }
          </div>
          <div className="md:col-span-8 text-center md:text-left">
            <h3 className="mb-8">{id}</h3>
            <p >
              {bio}
            </p>
            <Link to={authorLink}>View more by {id}</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthorBlock
