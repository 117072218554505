import React from 'react'
import ReactYouTube from '@u-wave/react-youtube'
import styles from "./YouTube.module.css"

const YouTube: React.FC = (props) => (
  <div className={`${styles.root}`}>
    <ReactYouTube {...props} />
  </div>
)

export default YouTube