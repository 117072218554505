import React, { FunctionComponent } from "react"

interface Props {
  className: string
  title: string
}

const Twitter: FunctionComponent<Props> = ({ className, title }) => (
  <svg
    className={`${className} fill-current`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <title>{title}</title>
    <path d="M11.46 8.83l.039.623-.63-.076c-2.291-.292-4.293-1.284-5.993-2.949l-.831-.826-.214.61c-.453 1.36-.164 2.797.78 3.763.504.534.39.61-.478.292-.302-.102-.566-.178-.592-.14-.088.09.214 1.246.454 1.703.327.636.994 1.259 1.725 1.627l.617.293-.73.012c-.706 0-.731.013-.655.28.251.826 1.246 1.703 2.354 2.085l.78.267-.68.406a7.09 7.09 0 01-3.374.941c-.566.013-1.032.064-1.032.102 0 .127 1.536.839 2.43 1.118 2.682.827 5.867.47 8.26-.94 1.7-1.005 3.399-3 4.192-4.932.428-1.03.856-2.911.856-3.813 0-.585.038-.661.743-1.36.416-.407.806-.852.881-.98.126-.24.114-.24-.528-.025-1.07.382-1.222.33-.693-.241.39-.407.856-1.144.856-1.36 0-.038-.189.025-.403.14-.226.127-.73.317-1.108.432l-.68.216-.616-.42c-.34-.229-.819-.483-1.07-.559-.643-.178-1.625-.153-2.204.05-1.574.573-2.568 2.047-2.455 3.662z" />
  </svg>
)

export default Twitter
