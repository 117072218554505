import React, { FunctionComponent } from "react"

interface Props {
  className: string
  title: string
}

const LinkedIn: FunctionComponent<Props> = ({ className, title }) => (
  <svg
    className={`${className} fill-current`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <title>{title}</title>
    <path d="M6.32 8.908H2.24v12.257h4.08V8.908zM6.59 5.117C6.562 3.915 5.702 3 4.307 3 2.912 3 2 3.915 2 5.117c0 1.177.885 2.119 2.255 2.119h.026c1.422 0 2.308-.942 2.308-2.119zM21.031 14.137c0-3.764-2.012-5.516-4.696-5.516-2.166 0-3.136 1.19-3.677 2.024V8.909h-4.08c.054 1.15 0 12.256 0 12.256h4.08V14.32c0-.366.026-.732.134-.994.295-.732.966-1.49 2.093-1.49 1.477 0 2.067 1.125 2.067 2.772v6.557h4.08v-7.028z" />
  </svg>
)

export default LinkedIn
